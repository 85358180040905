export default (theme) => ({
	hiddencheckbox: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		clippath: 'inset(50%)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		whiteSpace: 'nowrap',
		width: 1,
	},
	label: {
		fontFamily: theme.typography.sansSerif.fontFamily,
		fontSize: 12,
		marginLeft: 4,
		marginTop: 12,
		cursor: 'pointer',
		color: theme.palette.grey[400],
	},
	labelchecked: {
		color: theme.palette.grey[600],
	},
	errorInputStyle: {
		color: '#FFA938',
		fontSize: '0.75em',
	},
});
