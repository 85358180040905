const redMain = '#d40c24';

export default () => ({
	main: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		marginTop: '5%',
	},
	title: {
		fontFamily: 'Ultra System Sans',
		fontSize: '24px',
	},
	btn: {
		backgroundColor: redMain,
		border: 'none',
		color: 'white',
		cursor: 'pointer',
		fontSize: '14px',
		outline: 'none',
		padding: '10px 55px',
	},
	p: {
		backgroundColor: 'white',
		margin: '50px 0px',
		padding: '0px',
		textAlign: 'center',
		width: '40%',
	},
});
