export default () => ({

	img: {

		marginTop: '6rem',
	},
	// Start Media Queries
	'@media (min-width: 1024px)': {
		div: {
			width: '60%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			background: '#fff',
		},
		img: {
			height: '80%',
		},
	},
});
