import React from 'react';
import withStyles from 'react-jss';
import styles from './styles';

/**
 *
 * @property {String} Text
 */

const Subtitle = (props) => {
	const { classes, Text, children } = props;
	return (
		<>
			<h3 className={classes.SubtitleText}>
				{Text}
				<span className={classes.SecondText}>{children}</span>
			</h3>
		</>
	);
};

export default withStyles(styles)(Subtitle);
