import React from 'react';
import withStyles from 'react-jss';

const styles = (theme) => ({
	icon: {
		fill: 'none',
		stroke: 'white',
		strokeWidth: 2,
		visibility: ({ checked }) => (checked ? 'visible' : 'hidden'),
	},
	root: {
		display: 'inline-block',
		width: 18,
		minWidth: 18,
		height: 18,
		minWeight: 18,
		background: ({ checked }) => (checked ? theme.palette.primary.main : '#fffff'),
		border: ({ checked }) => (checked
			? `1px solid ${theme.palette.primary.main}`
			: `1px solid ${theme.palette.grey[400]}`),
		borderRadius: theme.shape.borderRadius,
		cursor: 'pointer',
		margin: 8,
	},
});

const Icon = (props) => <svg {...props} />;

const StyledCheckbox = ({ classes }) => (
	<div className={classes.root}>
		<Icon className={classes.icon} viewBox="0 0 24 24">
			<polyline points="20 6 9 17 4 12" />
		</Icon>
	</div>

);

export default withStyles(styles)(StyledCheckbox);
