import React from 'react';
import { Link } from 'react-router-dom';
import withStyles from 'react-jss';
import Angelo from 'assets/img/ANGELO.jpg';
import Navbar from '../Utils/Navbar';
import styles from './styles.js';


const Thanks = (props) => {
	const { classes } = props;
	return (
		<div>
			<Navbar />
			<div className={classes.left}>
				<div className={classes.img}>
					<img src={Angelo} alt="" />
				</div>
			</div>
			<div className={classes.right}>
				<h3 className={classes.titleOne}>WE'VE RECEIVED</h3>
				<h3 className={classes.titleTwo}>YOUR APPLICATION</h3>
				<p className={classes.p}>
				Once we have processed your application,
				you'll receive our news to notify you of the result
				</p>
				<p className={classes.p}>
					Meanwhile we invite you to know our
					<Link style={{ color: '#D40C24' }} to="/">website</Link>
				</p>
				<button className={classes.btn}>BACK TO HOMEPAGE</button>
			</div>
		</div>
	);
};

export default withStyles(styles)(Thanks);
