export default (theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
	},
	imgContainer: {
		width: 140,
		height: 140,
		border: '1px solid black',
		display: 'flex',
		overflow: 'hidden',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputContainer: {
		display: 'flex',
		marginLeft: 10,
		flexDirection: 'column',
		justifyContent: 'center',
	},
	input: {
		padding: '11px 0px',
		width: 160,
		backgroundColor: 'rgb(255, 255, 255)',
		border: '1px solid #d40c24',
		cursor: 'pointer',
		margin: 10,
		display: 'inline-block',
		outline: 0,
		fontFamily: theme.typography.sansSerif,
		borderRadius: 0,
		color: theme.palette.primary.main,
	},
	textHelper: {
		marginLeft: '10px',
		color: 'rgb(144, 144, 144)',
		fontSize: 12,
	},
	errorInputStyle: {
		color: '#FFA938',
		fontSize: '0.75em',
	},

	'@media(min-width: 1024px)': {
		root: {
			display: 'flex',
			justifyContent: 'flex-start',
		},
	},
});
