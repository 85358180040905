import React from 'react';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'react-jss';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { required, maxLength50 } from 'Components/containers/Wizard/validations/validate.js';
import styles from './styles.js';
// Form components
import Radio from '../Components/RadioButton';
import InputField from '../Components/InputField';
import CheckBox from '../Components/CheckBox';
import Button from '../Components/Button';
// Layout components
import Title from '../../ApplyTitle';
import Subtitle from '../../ApplySubtitle';

const PersonalInfo = (props) => {
	const { classes, handleSubmit } = props;
	function countryData(e) {
		localStorage.setItem('Country', e.target.value);
	}
	function cityData(e) {
		localStorage.setItem('City', e.target.value);
	}
	return (

		<section className={classes.sectionFull}>
			<Grid>
				<Row center="xs">
					<Col xs={10} sm={10} md={10} lg={10}>
						<Title FirstSentence="Tell Us" SecondSentence="About you" />
					</Col>
				</Row>
				<Row center="xs">
					<Col xs={10} sm={10} md={10} lg={10}>
						<Subtitle
							Text="Enter your data and complete your application to be part of "
						>
							{'HEADSMUSIC'}
						</Subtitle>
					</Col>
				</Row>
				<Row>
					<Col xs={10} sm={12} md={12} lg={9}>
						<p className={classes.requiredText}>
							<sup>*</sup>
							{' '}
							Required field
						</p>
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12}>
						<Row center="xs">
							<Col xs={10}>
								<form onSubmit={handleSubmit}>
									<Field
										name="name"
										type="text"
										component={InputField}
										label="Your Name *"
										validate={[required, maxLength50]}

									/>
									<div>
										<div style={{ display: 'flex', justifyContent: 'space-around' }}>
											<Field
												label="Soloist"
												name="musicalProfile"
												component={Radio}
												type="radio"
												value="soloist"
											/>
											<Field
												label="Band or group"
												name="musicalProfile"
												component={Radio}
												type="radio"
												value="band"
												validate={[required]}
											/>
											<Field
												label="Label"
												name="musicalProfile"
												component={Radio}
												type="radio"
												value="label"
												validate={[required]}
											/>
										</div>

									</div>

									<Field
										name="lastName"
										type="text"
										component={InputField}
										label="Your project's name *"
										validate={[required]}
									/>
									<div style={{ display: 'flex' }}>
										<Field
											label="It's the same as my real name"
											name="same"
											component={CheckBox}
											type="checkbox"
											validate={[required]}
										/>
									</div>
									<div className={classes.regionInputs}>

										<Col xs={12} sm={12} md={6} lg={6}>

											<div
												style={{
													display: 'inline-block',
													flexDirection: 'row',
													justifyContent: 'space-around',
													width: '100%',
												}}
											>
												<input
													type="text"
													placeholder="Country"
													onChange={countryData}
													className={classes.inputs}
												/>
											</div>
										</Col>
										<Col xs={12} sm={12} md={6} lg={6}>

											<div
												style={{
													display: 'inline-block',
													flexDirection: 'row',
													justifyContent: 'space-around',
													width: '100%',
												}}
											>
												<input
													type="text"
													placeholder="City"
													onChange={cityData}
													className={classes.inputs}
												/>
											</div>
										</Col>
									</div>

									<div className={classes.terms}>
										<Field
											label=""
											name="terms"
											component={CheckBox}
											type="checkbox"
											validate={[required]}
										/>
										<p className={classes.p}>
											I accept
											{' '}
											<a href="/" className={classes.textRed}>Terms and Conditions</a>
											{' '}
											and
											{' '}
											<a href="/" className={classes.textRed}>Privacy Policy</a>
											{' '}
											of Pyro.
											{' '}
											<br />
											And I accept
											{' '}
											<a href="/" className={classes.textRed}>Terms and Conditions</a>
											{' '}
											and
											{' '}
											<a href="/" className={classes.textRed}>Privacy Policy</a>
											{' '}
											of Bquate.
										</p>
									</div>
									<div>
										<Button>Next</Button>
									</div>
								</form>
							</Col>
						</Row>
					</Col>
				</Row>
			</Grid>

		</section>
	);
};

const PersonalInformation = withStyles(styles)(PersonalInfo);

export default reduxForm({
	form: 'wizard', //                 <------ same form name
	destroyOnUnmount: false, //        <------ preserve form data
	forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(PersonalInformation);
