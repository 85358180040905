export const theme = {
	name: 'HeadsMusic',
	typography: {
		useNextVariants: true,
		sansSerif: 'Graphik', // { fontFamily: ['Graphik'].join(','), },
		serif: { fontFamily: ['Yeseva One'].join(',') },
		header: {
			solid: 'Gobold Regular', // { fontFamily: ['Ultra System Sans'].join(','), },
			outline: 'Gobold Hollow', // { fontFamily: ['Ultra System Sans Outline'].join(','), }
		},
	},
	breakpoints: {
		sm: '@media (min-width: 320px)',
		md: '@media (min-width: 600px)',
		lg: '@media (min-width: 1280px)',
	},
	palette: {
		white: '#FFFFFF',
		black: '#000000',
		primary: {
			main: '#d40c24',
			50: '#f8e1e3',
			100: '#edb3ba',
			200: '#e1818c',
			300: '#d54e5e',
			400: '#d40c24',
			500: '#c30219',
			600: '#bd0216',
			700: '#b50112',
			800: '#ae010e',
			900: '#a10108',
			A100: '#ffcbcc',
			A200: '#ff989a',
			A400: '#ff6567',
			A700: '#ff4c4e',
			contrastDefaultColor: 'light',
		},
		secondary: {
			main: '#ffcf4d',
			50: '#fff9ea',
			100: '#fff1ca',
			200: '#ffe7a6',
			300: '#ffdd82',
			400: '#ffd668',
			500: '#ffcf4d',
			600: '#ffca46',
			700: '#ffc33d',
			800: '#ffbd34',
			900: '#ffb225',
			A100: '#ffffff',
			A200: '#ffffff',
			A400: '#ffeed3',
			A700: '#ffe5b9',
			contrastDefaultColor: 'dark',
		},
		grey: {
			main: '#272726',
			50: '#F2F2F2',
			100: '#bebebe',
			200: '#a0a0a0',
			300: '#686867',
			400: '#474747',
			500: '#272726',
			600: '#232322',
			700: '#1d1d1c',
			800: '#171717',
			900: '#0e0e0d',
			contrastDefaultColor: 'light',
		},
		error: {
			main: '#FF8172',
			600: '#FF8172',
			300: '#FF8172',
		},
		success: {
			main: '#3CA193',
		},
	},
	shape: {
		borderRadius: 0,
	},
	/**
     * @overwrites
     */
	overwrites: (theme) => ({
		input: {
			defaultProps: {
				placeholderToLabel: true,
				line: true,
			},
			root: {
				borderBottomStyle: 'solid',
				borderBottomWidth: 1,
				borderBottomColor: theme.palette.grey[500],
				borderBottomRadius: theme.shape.borderRadius,
				backgroundColor: 'transparent',
			},
		},
		button: {
			root: {},
			outlinedPrimary: {},
			primary: {
				'&:hover:not([disabled])': {
					backgroundColor: theme.palette.primary[600],
					'& img': {
						filter: 'invert(100%)',
					},
				},
			},
			outlinedSecondary: {
				backgroundColor: '#fff',
				border: `1px solid ${theme.palette.grey[200]}`,
				'&:hover:not([disabled])': {
					backgroundColor: theme.palette.grey[50],
					border: `1px solid ${theme.palette.grey[200]}`,
					'& p': {
						color: theme.palette.grey[200],
					},
				},
				'& p': {
					color: theme.palette.grey[200],
				},
			},
		},
		list: {
			border: 'none',
			backgroundColor: 'transparent',
		},
		listItem: {
			textIndent: '-1em',
			fontFamily: theme.typography.sansSerif.fontFamily,
			fontSize: 14,
			borderBottom: 'none',
			padding: 0,
			margin: '16px 0 16px 10px',
			'&::before': {
				content: '"\u25AA "',
				fontSize: 18,
				color: theme.palette.secondary.main,
			},
		},
		radio: {
			checkmark: {
				border: `1px solid ${theme.palette.grey[100]}`,
				backgroundColor: theme.palette.grey[50],
			},
			root: {
				'& input:checked ~ $checkmark': {
					backgroundColor: theme.palette.primary.main,
					border: `1px solid ${theme.palette.primary.main}`,
				},
				'& input:checked ~ $checkmark:after': { display: 'block' },
				'& $checkmark:after': {
					top: 5,
					left: 5,
					width: 8,
					height: 8,
					borderRadius: '50%',
					background: 'white',
				},
			},
		},
	}),
};
