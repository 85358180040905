export default () => ({
	container: {
		textAlign: 'center',
	},
	// Start Media
	'@media (min-width: 1024px)': {
		container: {
			display: 'flex',
			justifyContent: 'space-between',
			width: '100%',
			background: '#fff',
			marginTop: '-1px',
		},
	},
	'@media (min-width: 640px)': {
		container: {
			textAlign: 'center',
		},
	},
});
