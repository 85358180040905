export default (theme) => ({

	root: {
		width: '5%',
		position: 'absolute',
		top: '30%',
	},
	step: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	index: {
		margin: 0,
		marginTop: 30,
		marginBottom: 10,
		fontFamily: 'Ultra System Sans Outline',
		fontSize: 18,
		cursor: 'pointer',
		transition: '0.3s',
		color: '#D2D2D3',
	},
	divider: {
		height: 0,
		transition: '0.3s',
		width: 1,
		backgroundColor: theme.palette.primary.main,
	},
	dividerSelected: {
		height: 24,
		marginBottom: 30,
	},
	text: {
		whiteSpace: 'nowrap',
		pointerEvents: 'none',
		display: 'inline',
		fontFamily: 'Graphik',
		fontSize: 10,
		transform: 'rotate(270deg)',
		margin: 0,
		transition: '0.3s',
		opacity: 0,
	},
	selected: {
		opacity: 1,
		margin: '20px 0',
	},
	prev: { color: theme.palette.secondary.main, fontFamily: 'Ultra System Sans' },
	next: { color: 'red', fontFamily: 'Ultra System Sans' },
});
