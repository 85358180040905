import React from 'react';
import withStyles from 'react-jss';
import styles from './styles';

const Radio = ({
	input, label, type, meta: { touched, error }, classes,
}) => (
	<label htmlFor={input.id} className={[classes.root].join(' ')}>
		<input {...input} placeholder={label} type={type} />
		<span className={classes.checkmark} />
		{touched && error && <span className={classes.errorInputStyle}>{error}</span>}
		<span className={classes.label}>{label}</span>
	</label>
);

export default withStyles(styles)(Radio);
