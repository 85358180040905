const redColor = '#D40C28';

export default () => ({
	apply: {
		color: redColor,
	},
	now: {
		color: '#FFF',
		marginTop: '35px',
		fontFamily: 'Graphik',
		fontSize: '16px',
		textAlign: 'center',
	},
	btn: {
		backgroundColor: redColor,
		border: 'none',
		color: '#FFF',
		cursor: 'pointer',
		fontSize: '14px',
		marginTop: '35px',
		outline: 'none',
		padding: '14px 75px',
	},
	div: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
	},
	ul: {
		textAlign: 'center',
		padding: '0',
	},
	li: {
		color: '#000',
		fontFamily: 'Graphik',
		fontSize: '14px',
		letterSpacing: '0px',
		margin: '20px 0px',
	},
	divs: {
		padding: '0px',
		backgroundColor: '#fff',
	},
	socialMedia: {
		backgroundColor: '#fff',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		marginBottom: '10px',
		marginTop: '20px',
		width: '100%',
	},
	marginTop: {
		marginTop: '15px',
	},
	true: {
		color: '#000',
		fontFamily: 'Ultra System Sans',
		fontSize: '2em',
		fontWeight: '900',
		marginTop: '20%',
	},
	inde: {
		color: '#000',
		fontFamily: 'Ultra System Sans Outline',
		fontSize: '2em',
		fontWeight: '900',

	},
	spirit: {
		color: redColor,
		fontFamily: 'Ultra System Sans',
		fontSize: '2em',
		fontWeight: '900',
		textAlign: 'center',
	},
	line: {
		height: '2px',
		backgroundColor: redColor,
		width: '25px',
		margin: '0px 15px',
		display: 'none',
	},
	icons: {
		margin: '0px 10px',
		width: '20px',
		height: '20px',
		cursor: 'pointer',
	},
	follow: {
		color: '#000',
		display: 'none',
	},
	//	 Start Media Queries
	'@media (min-width: 1024px)': {
		apply: {
			color: redColor,
		},
		now: {
			color: '#FFF',
			marginTop: '35px',
			fontFamily: 'Graphik',
			fontSize: '16px',
			textAlign: 'center',
		},
		btn: {
			backgroundColor: redColor,
			border: 'none',
			color: '#FFF',
			cursor: 'pointer',
			fontSize: '14px',
			marginTop: '35px',
			outline: 'none',
			padding: '14px 75px',
		},
		div: {
			alignItems: 'center',
			display: 'flex',
			justifyContent: 'flex-start',
			width: '90%',
		},
		ul: {
			textAlign: 'left',
			width: '80%',
		},
		li: {
			color: '#000',
			fontFamily: 'Graphik',
			fontSize: '14px',
			letterSpacing: '0px',
			lineHeight: '90%',
			margin: '20px 0px',
			marginLeft: '-20px',
		},
		divs: {
			padding: '0px',
			backgroundColor: '#fff',
		},
		socialMedia: {
			alignItems: 'center',
			backgroundColor: '#fff',
			fontFamily: 'Graphik',
			fontSize: '12px',
			display: 'flex',
			justifyContent: 'flex-end',
			marginBottom: '10px',
			position: 'absolute',
			width: '30%',
		},
		marginTop: {
			marginTop: '15px',
		},
		true: {
			color: '#000',
			fontFamily: 'Ultra System Sans',
			fontSize: '40px',
			fontWeight: '900',
			marginTop: '40%',
			textAlign: 'center',
		},
		inde: {
			color: '#000',
			fontFamily: 'Ultra System Sans Outline',
			fontSize: '40px',
			fontWeight: '900',
			textAlign: 'left',
			marginTop: '-15px',
		},
		spirit: {
			color: redColor,
			fontFamily: 'Ultra System Sans',
			fontSize: '40px',
			fontWeight: '900',
			textAlign: 'right',
			marginLeft: '70%',
			marginTop: '-15px',
		},
		titles: {
			width: '58%',
			marginLeft: '-30px',
		},
		line: {
			height: '2px',
			backgroundColor: redColor,
			width: '25px',
			margin: '0px 15px',
			display: 'block',
		},
		icons: {
			margin: '0px 10px',
			width: '20px',
			height: '20px',
			cursor: 'pointer',
		},
		follow: {
			color: '#000',
			display: 'block',
		},
	},
	'@media (min-width: 640px)': {

	},

});
