import React, { useState, useEffect } from 'react';
import withStyles from 'react-jss';
import styles from './styles';


const InputField = ({
	input, classes, image, label, value, type, meta: { touched, error },
}) => {
	const [fieldActive, setActive] = useState('');

	/**
 * @constant
 * @name InputTextClasses
 *  Add or modify the component styles here 👇
 */

	const InputTextClasses = [
		classes.root,
		classes.placeholder,
		classes.line,
	].join(' ');

	useEffect(() => {
		setActive('on');
	}, []);

	return (
		<div className={classes.inputDivStyle}>
			{image && <img alt="images" className={classes.inputImgStyles} src={image} />}
			<div className={classes.inputContainer}>
				<label className={[classes.label, fieldActive, classes.inputLabel, classes.inputLabelStyle].join(' ')}>{label}</label>
				<input value={value} className={InputTextClasses} {...input} placeholder={label} type={type} />
				{touched && error && <span className={classes.errorInputStyle}>{error}</span>}
			</div>
		</div>
	);
};

export default withStyles(styles)(InputField);
