// const url = 'http://localhost:4502/login';
const API = 'http://api.headsmusic.bquate.com/';

function getData(data, service) {
	if (service === 'reset') {
		const u = `${API}resetpassword`;

		fetch(u, {
			crossDomain: true,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				email: 'hmsupport@bquate.com',
			},
		})
			.then(() => {

			})
			.catch(() => {

			});
	}
}

module.exports = getData;
