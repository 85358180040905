export default () => ({
	profilPictureText: {
		textAlign: 'center',
	},
	sectionFull: {
		background: '#FFF',
	},
	'@media(min-width: 1024px)': {
		profilPictureText: {
			textAlign: 'start',
		},
		sectionFull: {
			background: '#FFF',
			height: '100%',
		},
	},
});
