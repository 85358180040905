export default (theme) => ({
	root: {
		marginTop: '2.5em',
		width: 150,
		border: 'none',
		display: 'inline-block',
		padding: '14px 24px',
		borderRadius: theme.shape.borderRadius,
		cursor: 'pointer',
		margin: 10,
		outline: 0,
		fontFamily: theme.typography.sansSerif.fontFamily,
	},
	primary: {
		backgroundColor: theme.palette.primary.main,
		color: '#FFFFFF',
		'&:hover:not([disabled])': {
			'& p': {
				color: '#B40C24',
			},
			'& img': {
				filter: 'invert(100%)',
			},
		},
	},

});
