export default (theme) => ({
	NormalText: {
		color: '#686867',
		fontSize: 14,
		fontFamily: theme.typography.sansSerif.fontFamily,
		marginTop: '3.125em',
		fontWeight: 'normal',
	},
	SecondText: {
		color: theme.palette.black,
		fontSize: 14,
		lineHeight: '1em',
		fontFamily: theme.typography.sansSerif.fontFamily,
		fontWeight: 500,
		marginTop: '3.125em',
	},
	SubtitleText: {
		color: '#474747',
		fontSize: '1em',
		lineHeight: '1em',
		fontWeight: 'normal',
	},
});
