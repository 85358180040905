import React, { Component } from 'react';
import withStyles from 'react-jss';
import Wycleaf from './wycleaf.jpg';
import styles from './styles.js';

class SliderC extends Component {
	render() {
		const { classes } = this.props;
		return (
			<div className={classes.div}>
				<img src={Wycleaf} alt="" className={classes.img} />
			</div>
		);
	}
}

export default withStyles(styles)(SliderC);
