import React from 'react';
import withStyles from 'react-jss';
import styles from './styles';
import Navbar from '../Utils/Navbar/index.jsx';
import Info from '../Utils/Info/index.jsx';
import Slider from '../Utils/Slider/index.jsx';


const Landing = (props) => {
	const { classes } = props;
	return (
		<>
			<Navbar login />
			<div className={classes.container}>
				<Slider />
				<Info />
			</div>
		</>
	);
};

export default withStyles(styles)(Landing);
