import { useState } from 'react';

export function usePreview() {
	const [url, setUrl] = useState(null);

	function handlePreview(e) {
		const file = e.target.files[0];
		const reader = new FileReader();

		reader.readAsDataURL(file);
		reader.onload = () => {
			setUrl(reader.result);
		};
	}

	return [url, handlePreview];
}
