export default (theme) => ({
	root: {
		border: 'none',
		width: '100%',
		borderRadius: 6,
		backgroundColor: theme.palette.white,
		color: theme.palette.grey[900],
		fontSize: 14,
		lineHeight: '15px',
		padding: '18px 10px 16px',
		fontFamily: 'Graphik',
		'&:focus': { outline: 'none' },
		'&::placeholder': { color: '#D8DAE0' },
		boxSizing: 'border-box',
		...((theme.overwrites(theme) || {}).input || {}).root || {},
	},
	placeholder: {
		fontFamily: theme.typography.sansSerif.fontFamily,
		left: 8,
		transition: '0.3s',
		color: theme.palette.grey[400],
	},
	unfocused: (line) => {
		if (line) {
			return { top: 12, fontSize: 14 };
		}
		return { top: 15, fontSize: 14 };
	},
	focused: ({ line }) => {
		if (line) {
			return { top: -8, fontSize: 12 };
		}
		return { top: -10, fontSize: 12 };
	},
	line: {
		borderBottom: '1px solid #435278',
		borderRadius: 0,
		backgroundColor: 'transparent',
		padding: '8px 10px 0',
		height: 34,
		'&:active': {
			borderBottom: '1px solid #D2D2D3',
		},
		'&:focus': {
			borderBottom: '1px solid #D2D2D3',
		},
	},
	inputDivStyle: {
		margin: '1.75em 0px 1.75em 0px',
		textAlign: 'left',
	},
	inputLabelStyle: {
		color: '#909090',
		fontSize: '0.75em',
	},
	inputContainer: {
		display: 'contents',
		width: '90%',
		textAlign: 'center',
	},
	errorInputStyle: {
		color: '#FFA938',
		fontSize: '0.75em',
	},
	inputImgStyles: {
		display: 'inline-block',
		margin: '0px 12px 0px 12px',
		width: '1em',
	},

});
