import React, { Component } from 'react';
import withStyles from 'react-jss';
import { Link } from 'react-router-dom';
import Fly from 'assets/img/fly.svg';
import Navbar from '../Utils/Navbar';
import styles from './styles';


class Done extends Component {
	render() {
		const { classes } = this.props;
		return (
			<div>
				<Navbar />
				<div className={classes.main}>
					<h1 className={classes.title}>DONE!</h1>
					<img src={Fly} alt="" />
					<p className={classes.p}>
Check your email account and follow the instructions inside the email we just send you.
					</p>
					<Link to="/">
						<button className={classes.btn}>GOT IT</button>
					</Link>
				</div>
			</div>
		);
	}
}


export default withStyles(styles)(Done);
