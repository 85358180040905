const redColor = '#D40C24';

export default () => ({
	left: {
		width: '40vw',
		marginLeft: '10vw',
		height: '90vh',
		display: 'inline-flex',
		float: 'left',
		justifyContent: 'center',
		alignItems: 'center',
	},
	img: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	titleOne: {
		fontFamily: 'Ultra System Sans',
		fontSize: '24px',
		margin: 0,
		textAlign: 'left',
	},
	titleTwo: {
		fontFamily: 'Ultra System Sans Outline',
		fontSize: '24px',
		color: redColor,
		margin: 0,
		marginTop: '-12px',
		textAlign: 'right',
	},
	right: {
		width: '30vw',
		height: '90vh',
		display: 'inline-flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	btn: {
		backgroundColor: redColor,
		color: '#FFF',
		cursor: 'pointer',
		outline: 'none',
		border: 'none',
		padding: '15px 20px',
		fontSize: '14px',
		fontFamily: 'Graphik',
		width: '50%',
		marginTop: '35px',
	},
});
