export default () => ({
	sectionFull: {
		background: '#FFF',
	},

	'@media(min-width: 1024px)': {
		sectionFull: {
			background: '#FFF',
			height: '100%',
		},
	},
});
