import React, { useState } from 'react';
import withStyles from 'react-jss';
import lock from 'assets/img/email_reset_password_confirm.png';
import close from 'assets/img/close_secundary.svg';
import Navbar from 'Components/presentational/Utils/Navbar';
import styles from './styles.js';

// assets

const Reset = (props) => {
	const {
		classes,
	} = props;
	const [visible, setVisible] = useState(false);

	return (
		<div>
			{visible ? (
				<div className={classes.bgScreen}>
					<div className={classes.whiteContainer}>
						<div className={classes.headerContainer}>
							<div className={classes.titleCont}>
								<div>
									<img
										style={{
											height: '24px',
											width: '24px',
											marginRight: '20px',
										}}
										src={lock}
										alt="lock"
									/>
								</div>
								<div>
									<span className={classes.blackTitle}>READY!</span>
								</div>
							</div>
							<div>
								// eslint-disable-next-line jsx-a11y/click-events-have-key-events
								<img
									style={{
										height: '24px',
										width: '24px',
										marginLeft: '30px',
										cursor: 'pointer',
									}}
									src={close}
									alt="close"
									onClick={() => setVisible(false)}
								/>
							</div>
						</div>

						<section className={classes.infoContainer}>
							<div style={{ marginBottom: '2em' }}>
								<p className={classes.info}>
									Your password has been successfully changed.
								</p>
							</div>
						</section>
					</div>
				</div>
			) : (
				<div>
					<Navbar />
					<div className={classes.div}>
						<div className={classes.titles}>
							<p className={classes.titleOne}>Reset</p>
							<p className={classes.titleTwo}>Password</p>
						</div>
						// eslint-disable-next-line jsx-a11y/label-has-associated-control
						<label htmlFor="email">ID user e-mail</label>
						<input
							id="email"
							type="text"
							name="email"
							className={classes.input}
							placeholder="Email"
						/>
						<input
							type="password"
							placeholder="Enter your new password"
							autoComplete="off"
							className={classes.input}
						/>
						<input
							type="password"
							placeholder="Confirm your new password"
							autoComplete="off"
							className={classes.input}
						/>
						<button className={classes.btn} onClick={() => setVisible(true)}>
							NEXT
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default withStyles(styles)(Reset);
