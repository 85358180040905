import React, { useState, useEffect } from 'react';
import withStyles from 'react-jss';
import styles from './styles';
import StyledCheckBox from './StyledCheckBox';

const CheckBox = ({
	input, label, type, meta: { touched, error }, classes,
}) => {
	const [check, setCheck] = useState(input.checked);

	useEffect(() => {
		setCheck(input.checked);
	}, [input.checked]);

	return (
		<label>
			<input {...input} checked={check} type={type} className={classes.hiddencheckbox} />
			<StyledCheckBox checked={check} />
			<span className={[classes.label, check && classes.labelchecked].join(' ')}>{label}</span>
			{touched && error && <span className={classes.errorInputStyle}>{error}</span>}
		</label>
	);
};

export default withStyles(styles)(CheckBox);
