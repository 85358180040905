/* eslint-disable no-dupe-keys */
const redColor = '#D40C24';

export default (theme) => ({
	div: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		height: '85vh',
		justifyContent: 'flex-start',
	},
	btn: {
		backgroundColor: redColor,
		border: 'none',
		color: 'white',
		cursor: 'pointer',
		fontSize: '14px',
		outline: 'none',
		padding: '10px 55px',
		marginTop: '35px',
	},
	titleOne: {
		fontFamily: 'Ultra System Sans',
		fontSize: '24px',
		margin: 0,
		marginTop: '45px',
	},
	titleTwo: {
		color: redColor,
		fontFamily: 'Ultra System Sans Outline',
		fontSize: '24px',
		margin: 0,
		marginTop: '-10px',
		textAlign: 'right',
	},
	titles: {
		width: '15%',
	},
	label: {
		color: '#909090',
		fontFamily: 'Graphik',
		fontSize: '12px',
		margin: '25px 0px -12px 0px',
		width: '30%',
	},
	input: {
		border: 'none',
		backgroundColor: '#FFF',
		height: '30px',
		outline: 'none',
		borderBottom: '1px solid #CCC',
		'&:focus': {
			borderBottom: '1px solid #000',
		},
		placeholder: 'Email',
		margin: '10px 0px',
		width: '30%',
		content: '',
		placeholder: 'Email',
		border: 'none',
		width: '15%',
		height: '25px',
		outline: 'none',
		'&:hover': {
			borderBottom: '1px solid black',
		},
	},
	// modal styles
	bgScreen: {
		backgroundColor: '#010101',
		height: '100vh',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	whiteContainer: {
		backgroundColor: theme.palette.white,
		height: '13em',
		width: '680px',
		[theme.breakpoints.md]: {},
		[theme.breakpoints.sm]: {},
		[theme.breakpoints.lg]: {},
	},
	headerContainer: {
		borderBottom: '1px solid #D2D2D3',
		padding: '20px',
		height: '50px',
		marginBottom: '2em',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'baseline',
	},
	titleCont: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'baseline',
	},
	infoContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	blackTitle: {
		fontFamily: theme.typography.header.solid,
		fontSize: '1.8em',
		lineHeight: '2em',
		height: '29px',
		width: '429px',
		color: theme.palette.black,
		textTransform: 'uppercase',
		marginRight: '20px',
	},
	info: {
		height: 16,
		width: '641px',
		color: '#000000',
		fontFamily: theme.typography.sansSerif,
		fontSize: '14px',
		lineHeight: '16px',
		textAlign: 'center',
	},
});
