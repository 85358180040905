export default ({
	titles: {
		true: 'TRUE',
		ind: 'INDEPENDENT',
		spirit: 'SPIRIT',
	},
	bullets: {
		sell: 'Sell your music on iTunes, Spotify, Amazon Music and a wide network of popular music stores.',
		global: 'Global digital distribution, release management, playlist nomination, YouTube monetization and more.',
		transparent: 'Transparent information and complete reports.',
		collect: 'Collect your worldwide songwriter royalties.',
	},
	apply: {
		now: 'APPLY NOW, ',
		weare: ' WE\'RE YOUR NEW PARTNERS!',
		button: 'APPLY',
	},
	follow: 'FOLLOW',
	socialNetworks: {
		twitter: 'https://twitter.com/headsmusic',
		youTube: 'https://www.youtube.com/channel/UCmCkan9KpS77xZTFmVEW5fQ',
		instagram: 'https://www.instagram.com/headsmusic/',
		facebook: 'https://www.facebook.com/headsentertainment',
	},
});
