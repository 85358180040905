// You will add the new validations BELOW 👇

export const required = (value) => (value ? undefined : 'Required');

const maxLength = (max) => (value) => (value && value.length > max ? `Must be ${max} characters or less` : undefined);
export const maxLength50 = maxLength(50);

const getRegexURLs = (name) => {
	switch (name) {
		case 'spotify':
		case 'youtube':
		case 'soundcloud':
		case 'instagram':
			return new RegExp(`^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1})${name}*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)[a-zA-Z0-9.-]{1,100}?$`);
		default:
			return new RegExp('^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)[a-zA-Z0-9.-]{1,100}?$');
	}
};
export const isValidURL = (value, allValues, props, name) => {
	if (value && !getRegexURLs(name).test(value)) return `${name} url must be valid`;
	return undefined;
};

// email validation

export const email = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
	? 'Invalid email address' : undefined);

// Password Validations
export const matchPassword = (value, allValues) => (value !== allValues.password ? 'Password not match' : undefined);
