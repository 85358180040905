const redMain = '#d40c24';

export default () => ({
	navbar: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '6.25em',
		width: '100%',
	},
	btnLogo: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	title: {
		color: 'red',
	},
	login: {
		backgroundColor: '#fff',
		color: redMain,
		border: `1px solid ${redMain}`,
		padding: '10px 40px',
		fontSize: '14px',
		cursor: 'pointer',
		outline: 'none',
		height: '50%',
		marginRight: '15px',
		textAlign: 'center',
	},
	pbb: {
		display: 'none',
	},
	// Start Media 	Queries
	'@media (min-width: 1024px)': {
		navbar: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			height: '6.25em',
			width: '100%',
		},
		btnLogo: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginRight: '10px',
		},
		title: {
			color: 'red',
		},
		login: {
			backgroundColor: '#fff',
			color: redMain,
			border: `1px solid ${redMain}`,
			padding: '10px 40px',
			fontSize: '14px',
			cursor: 'pointer',
			outline: 'none',
			height: '50%',
			marginRight: '15px',
			textAlign: 'center',
		},
		pbb: {
			width: '150px',
			display: 'inline',
		},
		'@media (min-width: 640px)': {
			btnAnchor: {
				marginLeft: '10px',
			},
		},
	},
});
